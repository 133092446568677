$(function() {

// Accordion Menu
  $('nav > ul > li,nav > ul > li > a[href^=#]').on('click', function() {
    $(this).children('ul:not(:animated)').slideToggle();
    $(this).children('span').toggleClass('open');
    $('.sub-menu').not($(this).children('.sub-menu')).slideUp();
    $('nav li').children('span').not($(this).children('span')).removeClass('open');
  });
    $('.sub-menu li a[href^=#]').on('click', function(event) {
    event.stopPropagation();
  });
  
// Menu open button behavior
  var text = $('.open-text');
  $('#open').click(function() {
    $('#menu-wrap').slideToggle(300);
    $('#side-bg').fadeToggle();
    $('#open').toggleClass('buttonclose');
    $('#open-icon').toggleClass('close');
    if ($('#open-icon').hasClass('close')) {
      text.text('Close');
    }
    else {
      text.text('Menu');
    }
    return false;
  });

// When pressing outside the range in small screen mode, close once
  $('#side-bg').click(function() {
    $(this).fadeOut();
    $('#menu-wrap').slideToggle(300);
    $('#open').removeClass('buttonclose');
    $('#open-icon').removeClass('close');
    $('.open-text').text('Menu');
  });

// When clicking a link in the menu on a small screen, it will close once
// For internal links
  $('#menu-wrap a').on('click', function() {
    if (window.innerWidth <= 600) {
      $('#open').click();
    }
  });

// Prevent the menu from disappearing when resizing after closing the menu on a small screen
  $(window).resize(function() {
    var menu = $('#menu-wrap');
    var w = window.innerWidth ? window.innerWidth : $(window).width();
    if (w > 600 && menu.is(':hidden')) {
      menu.attr('style', '');
    }
  });

// Do not decorate image links
  $('img').parent('a').addClass('bg-none');

});
